<template>
  <div>
    <fw-panel :title="'Mover equipas de fase'">
      <template v-if="step == 'confirm'">
        <fw-panel-info v-if="checkingTeams" clean class="mx-auto h-16 flex items-center justify-center m-5">
          <fw-icon-loading class="animate-spin w-10 h-10 m-auto" />
          A verificar o número de equipas...
        </fw-panel-info>
        <div v-else>
          <div class="rounded-full mx-auto w-16 h-16 bg-primary flex items-center justify-center m-5">
            <fw-icon-message-sent class="fill-current h-8 w-8 text-white"></fw-icon-message-sent>
          </div>
          <p class="text-gray-500">
            Vão ser movidas <strong> {{ teamsCount ?? 0 }} equipas</strong>.
          </p>
        </div>
      </template>

      <div v-else class="my-2">
        <div v-if="teamToMove">
          <fw-heading size="md" class="mb-2">Equipa</fw-heading>
          <div class="flex flex-col gap-4">
            <div>
              <fw-label>Fase do torneio</fw-label>

              <fw-panel-info clean>{{ STAGES[teamToMove.stage][language] }} </fw-panel-info>
            </div>

            <div v-if="teamToMove.stage == 'intragroups' && teamToMove.group_key">
              <fw-label class="space-x-2">Núcleo </fw-label>
              <fw-panel-info clean>{{ teamToMove.team_group.name }}</fw-panel-info>
            </div>
          </div>
        </div>
        <div v-else>
          <fw-heading size="md" class="mb-2">Defina as equipas a mover</fw-heading>

          <div class="flex flex-col gap-4">
            <div>
              <fw-label>Fase do torneio</fw-label>

              <SelectableTags
                :multiple="false"
                :selected-options.sync="teamsData.fromStage"
                :options="STAGES"
                class="mt-1"
                @selected="teamsData.fromTeamGroup = null"
              ></SelectableTags>
              <fw-tip v-if="$v.teamsData.fromStage.$error" error>
                {{ $t('required.fromStage') }}
              </fw-tip>
            </div>

            <div v-show="!teamsData.fromStage?.length || teamsData.fromStage?.includes('intragroups')">
              <fw-label class="space-x-2"
                >Núcleos <fw-tag type="xlight" size="xs">{{ $t('optional') }}</fw-tag></fw-label
              >
              <multiselect
                v-model="teamsData.fromTeamGroup"
                :options="teamGroupsOptions"
                :show-labels="false"
                :clear-on-select="false"
                :allow-empty="true"
                :multiple="true"
                track-by="key"
                label="key"
                placeholder="Selecione os núcleos..."
                :searchable="true"
                :options-limit="limit"
                :custom-label="teamGroupLabel"
                :loading="loadingTeamGroups"
                @search-change="getTeamGroups"
              >
              </multiselect>
              <div class="text-xs text-gray-400 py-1">
                Se não selecionar um núcleo, todas as equipas serão movidas.
              </div>
            </div>

            <div v-show="teamsData.fromStage == 'intergroups'">
              <fw-label
                >Unidades orgânicas <fw-tag type="xlight" size="xs">{{ $t('optional') }}</fw-tag></fw-label
              >
              <multiselect
                v-model="teamsData.fromOrganicUnit"
                :options="organicUnitOptions"
                :show-labels="false"
                :clear-on-select="false"
                :allow-empty="true"
                :multiple="true"
                track-by="key"
                placeholder="Selecione as unidades orgânicas..."
                :searchable="true"
                :options-limit="limit"
                label="name"
                :loading="loadingOrganicUnits"
                @search-change="getOrganicUnits"
              >
              </multiselect>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <fw-heading size="md" class="mb-2">Defina a fase para onde mover as equipas</fw-heading>

          <div class="flex flex-col gap-4">
            <div>
              <fw-label>Fase do torneio</fw-label>

              <SelectableTags
                :multiple="false"
                :selected-options.sync="teamsData.toStage"
                :options="STAGES"
                class="mt-1"
                @selected="teamsData.toTeamGroup = null"
              ></SelectableTags>

              <fw-tip v-if="$v.teamsData.toStage.$error" error>
                {{ $t('required.toStage') }}
              </fw-tip>
            </div>

            <div v-show="teamsData.toStage == 'intragroups'">
              <fw-label>Núcleos</fw-label>
              <multiselect
                v-model="teamsData.toTeamGroup"
                :options="teamGroupsOptions"
                :show-labels="false"
                :clear-on-select="false"
                :allow-empty="true"
                :multiple="false"
                track-by="key"
                placeholder="Selecione os núcleos..."
                :searchable="true"
                :options-limit="limit"
                :custom-label="teamGroupLabel"
                :loading="loadingTeamGroups"
                @search-change="getTeamGroups"
              >
              </multiselect>

              <fw-tip v-if="$v.teamsData.toTeamGroup.$error" error>
                {{ $t('required.toTeamGroup') }}
              </fw-tip>
            </div>

            <div v-show="teamsData.toStage == 'intergroups'">
              <fw-label>Unidades orgânicas</fw-label>
              <multiselect
                v-model="teamsData.toOrganicUnit"
                :options="organicUnitOptions"
                :show-labels="false"
                :clear-on-select="false"
                :allow-empty="true"
                :multiple="false"
                track-by="key"
                placeholder="Selecione as unidades orgânicas..."
                :searchable="true"
                :options-limit="limit"
                label="name"
                :loading="loadingOrganicUnits"
                @search-change="getOrganicUnits"
              >
              </multiselect>

              <fw-tip v-if="$v.teamsData.toOrganicUnit.$error" error>
                {{ $t('required.toOrganicUnit') }}
              </fw-tip>
            </div>
          </div>
        </div>

        <fw-panel-info v-if="showInfo" type="orange" boxed clean icon class="mt-5">
          <span>Apenas equipas já <span class="font-bold">Aprovadas</span> serão movidas.</span>
        </fw-panel-info>
      </div>
    </fw-panel>

    <div class="flex items-center justify-end gap-5 mt-5">
      <fw-button type="link-muted" :disabled="saving || savingData" class="mr-auto" @click.native="close">
        {{ $t('cancel') }}
      </fw-button>

      <fw-button
        v-if="step != 'start'"
        type="link-muted"
        :disabled="saving || savingData"
        @click.native="previousStep()"
      >
        {{ $t('back') }}
      </fw-button>

      <fw-button v-else type="primary" class="px-5" @click.native="nextStep()">
        {{ $t('confirm') }}
      </fw-button>

      <fw-button
        v-if="step == 'confirm'"
        type="primary"
        class="px-5"
        :disabled="saving || savingData"
        :loading="saving || savingData"
        @click.native="updateTeams"
      >
        <template #icon>
          <fw-icon-send-plane class="w-5 h-5" />
        </template>
        {{ $t('confirm') }}
      </fw-button>
    </div>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ teamsCount, teamsData, teamGroupsOptions, teamToMove }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import SelectableTags from '@/fw-modules/fw-core-vue/ui/components/form/SelectableTags'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { TOURNAMENT_STAGES } from '@/utils/index.js'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  components: {
    Multiselect,
    SelectableTags
  },

  validations: {
    teamsData: {
      fromStage: { required },
      toStage: { required },
      toTeamGroup: {
        required: requiredIf(nestedModal => {
          return nestedModal.toStage === 'intragroups'
        })
      },
      toOrganicUnit: {
        required: requiredIf(nestedModal => {
          return nestedModal.toStage === 'intergroups'
        })
      }
    }
  },

  props: {
    tournamentKey: {
      type: String
    },
    stages: {
      type: Object,
      default: () => {}
    },
    teamToMove: {
      type: Object
    },
    defaultFromStage: {
      type: String,
      default: null
    },
    showInfo: {
      type: Boolean,
      default: true
    },
    emitUpdateOnly: {
      type: Boolean,
      default: false
    },
    savingData: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      limit: 50,
      saving: false,
      teamsData: {
        fromStage: [],
        toStage: null,
        fromTeamGroup: null,
        fromOrganicUnit: null,
        toTeamGroup: null,
        toOrganicUnit: null
      },
      STAGES: {},
      loadingTeamGroups: false,
      teamGroupsOptions: [],
      step: 'start',
      teamsCount: null,
      checkingTeams: false,
      organicUnitOptions: [],
      loadingOrganicUnits: false
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language || 'pt'
    }
  },

  mounted() {
    this.getTeamGroups()
    this.getOrganicUnits()

    const tournamentStages = { ...TOURNAMENT_STAGES }
    for (const stage of Object.keys(tournamentStages)) {
      if (this.stages[stage].every(stage => stage.started_at)) {
        console.log('stage', stage)
        delete tournamentStages[stage]
      }
    }

    this.STAGES = tournamentStages
    this.teamsData.fromStage =
      this.defaultFromStage && Object.keys(this.STAGES).includes(this.defaultFromStage) ? this.defaultFromStage : null

    console.log('this.teamsData', this.teamsData)
  },

  methods: {
    teamGroupLabel(teamGroup) {
      return `${teamGroup.organic_unit?.name} - ${teamGroup.name}`
    },

    previousStep() {
      if (this.step == 'confirm') {
        this.step = 'start'
        return
      }
    },

    nextStep() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.step == 'start') {
        this.getTeamsCount()
        this.step = 'confirm'
        return
      }
    },

    getStageKeys(from = true) {
      let stageType, organicUnits, teamGroups
      if (from) {
        stageType = this.teamsData.fromStage
        organicUnits = this.teamsData.fromOrganicUnit?.map(el => el.key)
        teamGroups = this.teamsData.fromTeamGroup?.map(el => el.key)
        if (this.teamToMove) {
          stageType = this.teamToMove.stage
          teamGroups = this.teamToMove.stage == 'intragroups' ? [this.teamToMove.group_key] : null
        }
      } else {
        stageType = this.teamsData.toStage
        organicUnits = this.teamsData.toOrganicUnit?.key ? [this.teamsData.toOrganicUnit?.key] : []
        teamGroups = this.teamsData.toTeamGroup?.key ? [this.teamsData.toTeamGroup?.key] : []
        if (this.teamToMove) {
          stageType = this.teamsData.toStage
        }
      }

      if (!stageType) return

      let stages = []
      if (organicUnits && organicUnits?.length) {
        stages = this.stages[stageType].filter(
          stage => organicUnits.includes(stage.organic_unit) && ((!from && !stage.started_at) || from)
        )
      } else if (teamGroups && teamGroups?.length) {
        stages = this.stages[stageType].filter(
          stage => teamGroups.includes(stage.team_group) && ((!from && !stage.started_at) || from)
        )
      } else {
        const x = this.stages[stageType][0]
        console.log((!from && !x.started_at) || from)
        stages = this.stages[stageType].filter(stage => (!from && !stage.started_at) || from)
      }

      return from ? stages.map(stage => stage.key) : stages[0]?.key
    },

    getTeamsCount() {
      this.teamsCount = 1
      let toStage = this.getStageKeys(false)
      if (!toStage) {
        this.$buefy.dialog.alert({
          title: 'Mover equipas',
          message: 'A fase que escolheu não está disponível.',
          type: 'is-danger',
          confirmText: 'Ok'
        })
        return
      }

      if (this.teamToMove) {
        return
      }

      utils.tryAndCatch(
        this,
        async () => {
          this.checkingTeams = true
          const response = await this.api.managementMoveTeams(this.tournamentKey, {
            from_stage: this.getStageKeys(true),
            to_stage: toStage,
            confirm: false
          })
          console.log('checkMessage :>> ', response)
          this.teamsCount = response.count
        },
        async () => {
          await utils.sleep(500)
          this.checkingTeams = false
        }
      )
    },

    close() {
      this.step = 'start'
      this.$emit('close')
    },

    updateTeams() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.teamsCount > 0) {
        this.$buefy.dialog.confirm({
          confirmText: 'Mover',
          cancelText: 'Cancelar',
          title: `Mover equipas`,
          message: `Vão ser movidas <strong>${this.teamsCount}</strong> equipas para a <strong>${
            this.STAGES[this.teamsData.toStage][this.language]
          }</strong>. Tem a certeza que deseja enviar?`,
          onConfirm: () => {
            this.$emit('update', {
              from_stage: this.getStageKeys(true),
              to_stage: this.getStageKeys(false),
              to_stage_type: this.teamsData.toStage
            })

            if (!this.emitUpdateOnly) {
              utils.tryAndCatch(
                this,
                async () => {
                  this.saving = true
                  const response = await this.api.managementMoveTeams(this.tournamentKey, {
                    from_stage: this.getStageKeys(true),
                    to_stage: this.getStageKeys(false),
                    confirm: true
                  })
                  console.log('managementMoveTeams :>> ', response)
                  this.$emit('moved', response)
                  this.$buefy.snackbar.open({
                    message: 'Equipas atualizadas com sucesso',
                    type: 'is-primary',
                    position: 'is-bottom-right',
                    duration: 5000
                  })
                },
                async () => {
                  await utils.sleep(250)
                  this.saving = false
                  this.close()
                  this.$emit('moved')
                }
              )
            }
          }
        })
      } else {
        this.$buefy.dialog.alert({
          title: 'Mover equipas',
          message: 'Não há equipas a mover.',
          type: 'is-danger',
          confirmText: 'Ok'
        })
      }
    },

    // For multiselect
    async getTeamGroups(query) {
      this.loadingTeamGroups = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getTeamGroups({
            query,
            with_org_units: true,
            limit: this.limit
          })
          console.log('getTeamGroups :>> ', response)
          this.teamGroupsOptions = response.groups.sort((a, b) =>
            `${a.organic_unit.name} - ${a.name}`.localeCompare(`${b.organic_unit.name} - ${b.name}`)
          )
        },
        () => {
          this.loadingTeamGroups = false
        }
      )
    },

    async getOrganicUnits(query) {
      this.loadingOrganicUnits = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getOrganicUnits(query)
          console.log('getOrganicUnits :>> ', response)
          this.organicUnitOptions = response.data
        },
        () => {
          this.loadingOrganicUnits = false
        }
      )
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "cancel": "Cancelar",
    "confirm": "Confirmar",
    "optional": "Opcional",
    "back": "Voltar",
    "required": {
      "toStage": "Selecione a fase para onde mover as equipas",
      "fromStage": "Selecione pelo menos uma fase",
      "toTeamGroup": "Selecione pelo menos um núcleo",
      "toOrganicUnit": "Selecione pelo menos uma unidade organica"
    }
  },
  "en": {
    "cancel": "Cancel",
    "confirm": "Confirm",
    "optional": "Optional",
    "back": "Back",
    "required": {
      "toStage": "Select at least one stage for moving teams",
      "fromStage": "Select at least one stage",
      "toTeamGroup": "Select at least one team group",
      "toOrganicUnit": "Select at least one organic unit"
    }
  }
}
</i18n>
