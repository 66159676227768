<template>
  <PanelActivity
    api-endpoint="getActivityLogs"
    :active-filters="activeFilters"
    :inject-payload="{ tournamentKey: tournamentKey }"
  />
</template>

<script>
import PanelActivity from '@/fw-modules/fw-core-vue/ui/components/panels/PanelActivity'

export default {
  components: {
    PanelActivity
  },

  props: {
    tournamentKey: {
      type: String
    }
  },

  data() {
    return {
      activeFilters: {
        tournament: this.tournamentKey
      }
    }
  }
}
</script>
