<template>
  <div>
    <PanelCreateTeam
      :team="team"
      :ask-group-key="askGroupKey"
      :tmp-team-member="tmpTeamMember"
      :team-errors="$v.team"
      :verifying-user="verifyingUser"
      :can-save-team="canSaveTeam"
      :saving="saving"
      :minimum-team-members-error="minimumTeamMembersError"
      :show-submit-button="false"
      :boxed="false"
      :management="true"
      :logo="logo"
      @update-team-image="updateTeamImage(uploadedFiles)"
      @cancel="cancelTeam"
      @remove-logo="removeLogo"
      @save-team="saveTeam(false, false, $event)"
      @select-color="selectColor"
      @select-icon="selectIcon"
      @set-leader="setLeader"
      @remove-player="removeTeamMember"
      @add-player="addTeamMember"
    ></PanelCreateTeam>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="closeModal()">
        {{ 'Cancelar' }}
      </fw-button>
      <fw-button :type="'primary'" :disable="saving" :saving="saving" class="w-28" @click.native="saveTeam(true, true)">
        {{ 'Criar' }}
      </fw-button>
    </div>
  </div>
</template>
<script>
import { required, minLength } from 'vuelidate/lib/validators'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import PanelCreateTeam from '@/components/panels/PanelCreateTeam'

export default {
  name: 'ModalCreateTeam',
  components: {
    PanelCreateTeam
  },
  props: {
    editionKey: {
      type: String,
      required: true
    },
    leagueKey: {
      type: String,
      required: true
    },
    tournament: {
      type: Object
    }
  },
  data() {
    return {
      uploadFiles: [],
      saving: false,
      tmpTeamMember: '',
      verifyingUser: false,
      team: {
        key: null,
        tournament_key: null,
        name: null,
        logo: null,
        leader: null,
        color: null,
        status: 'draft',
        players: [],
        submitted_at: null,
        confirmed_at: null,
        denied_at: null,
        deleted_at: null
      },
      logo: null,
      teamGroups: [],
      errors: []
    }
  },
  validations: {
    team: {
      name: { required, min: minLength(1) },
      players: { required, min: minLength(1) }
    }
  },
  computed: {
    tournamentKey() {
      return this.tournament.key
    },
    user() {
      return this.$store.getters.getUser
    },
    api() {
      return this.$store.state.api.base
    },
    canSaveTeam() {
      return this.team.name != '' && this.team.players.length > 0
    },
    // Checks
    isRunning() {
      return this.tournament.status == 'running'
    },
    isOpenToApplications() {
      return this.tournament.status == 'signup-open'
    },
    isClosedToApplications() {
      return this.tournament.status == 'signup-closed'
    },
    sportName() {
      if (this.tournament.sport.category != null) {
        return this.tournament.sport.modality.title + ' - ' + this.tournament.sport.category
      }
      return this.tournament.sport.modality.title
    },
    askGroupKey() {
      return !this.tournament.league.open_signup ?? false
    },
    maxNumberTeamMembers() {
      return this.tournament.sport.max_players ?? 0
    },
    teamKey() {
      return this.$route.params.teamKey
    }
  },
  mounted() {
    this.getTeamGroups()
  },
  methods: {
    cancelTeam() {
      this.$buefy.dialog.confirm({
        confirmText: 'Cancelar inscrição',
        type: 'is-danger',
        cancelText: 'Sair',
        title: `Cancelar inscrição da equipa`,
        message: `Tem a certeza que deseja cancelar a inscrição da sua equipa?`,
        onConfirm: () => {
          this.cancelTeamRequest()
        }
      })
    },
    async cancelTeamRequest() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.cancelTeamSignUp(this.tournamentKey, this.teamKey)
        console.log('cancelTeamRequest :>> ', response)
        this.$router.push({ name: 'home' })
      })
    },
    setLeader(leader) {
      this.team.leader = leader
      this.saveTeam(false, false)
    },

    removeLogo() {
      this.logo = null
      this.team.logo_key = null
    },
    selectIcon(icon) {
      this.team.icon_key = icon
      this.removeLogo()
      this.saveTeam(false, false)
    },
    selectColor(color) {
      this.team.color = color
      this.removeLogo()
      this.saveTeam(false, false)
    },
    removeTeamMember(i) {
      this.team.players.splice(i, 1)
      this.saveTeam(false, false)
    },
    addTeamMember(teamMember) {
      this.team.players.push(teamMember)
      this.saveTeam(false, false)
    },
    async updateTeamImage(uploadedFiles) {
      var file = uploadedFiles[0]
      this.team.logo_key = file.response.data.file.key
      console.log('updateTeamImage', file.response.data.file.key)
      this.saveTeam(false, false)
    },

    async saveTeam(validate = true, submit = false, teamData) {
      console.log('saveTeam :>> ', { validate, submit, teamData })
      if (this.saving) return
      if (validate) {
        this.errors = []
        this.$v.team.$touch()
        if (this.$v.team.$error) return
      }
      try {
        this.saving = true
        let data = teamData
        if (submit) {
          data['submit'] = true
        }
        const response = this.team.key
          ? await this.api.updateTeam(this.tournamentKey, this.team.key, data)
          : await this.api.createTeam(this.tournamentKey, data)
        console.log('saveTeam :>> ', response)
        if (response.key) {
          this.team.key = response.key
        }
        this.logo = response.logo

        if (response?.['__errors__']?.length) this.setTeamErrors(response['__errors__'])

        if (submit) this.$emit('saved')
      } catch (error) {
        console.error('error :>> ', error)
        this.setTeamErrors(error.response.data['__errors__'])
      }
      this.saving = false
    },

    setTeamErrors(errorData) {
      console.error('setTeamErrors :>> ', errorData)
      let errorMessage = this.$t('errors.default')
      if (errorData && errorData.length > 0) {
        const errorKey = errorData[0].key

        switch (errorKey) {
          case 'NotFound':
            errorMessage = this.$t('errors.tournamentNotFound')
            break
          case 'InvalidOrganicUnit':
            errorMessage = this.$t('errors.invalidOrganicUnit')
            break
          case 'InvalidTournamentStage':
            errorMessage = this.$t('errors.invalidTournamentStage')
            break
          case 'SignupNotAvailable':
            errorMessage = this.$t('errors.signupNotAvailable')
            break
          case 'TeamNameAlreadyExists':
            errorMessage = this.$t('errors.teamNameAlreadyExists')
            break
          case 'Forbidden':
            errorMessage = this.$t('errors.noPermissionsToCreate')
            if (errorData[0].detail == 'Already leader of a team') {
              errorMessage = this.$t('errors.alreadyLeaderOfTeam')
            }
            break
          case 'UserAlreadyInTeam':
            errorMessage = this.$t('errors.userAlreadyInTeam', {
              email: errorData[0].email
            })
            break
          case 'InvalidEmail':
            errorMessage = this.$t('errors.invalidEmail')
            // Only allow students in academic league. Ignore for local dev
            if (errorData[0].detail.includes('Only students')) {
              errorMessage = this.$t('errors.onlyStudents')
            }
            break

          default:
            break
        }
      }

      this.$buefy.dialog.alert({
        message: errorMessage,
        type: 'is-danger'
      })
      this.errors = errorData
    },

    async getTeamGroups() {
      try {
        const response = await this.api.getTeamGroups({
          sort: 'name',
          direction: 'asc',
          with_org_units: true
        })
        console.log('getTeamGroups :>> ', response)
        this.teamGroups = response.data
      } catch (error) {
        console.log('Error getTeamGroups :>> ', error)
      }
    }
  }
}
</script>

<i18n lang="json">
  {
    "en": {
      "errors": {
        "default": "An error occurred when registering the team.",
        "tournamentNotFound": "The tournament could not be found.",
        "invalidOrganicUnit": "The organic unit selected is not valid.",
        "invalidTournamentStage": "The tournament is not at the registration stage.",
        "signupNotAvailable": "Registration for the tournament is not available.",
        "teamNameAlreadyExists": "There is already a team with that name.",
        "alreadyLeaderOfTeam": "You are already a team leader.",
        "noPermissionsToCreate": "You don't have permissions to create teams for this organic unit.",
        "minPlayers": "A team must have at least {min_number} players. Just {total} of {min_number} added",
        "invalidEmail": "Invalid e-mail address.",
        "onlyStudents": "Only students can be invited.",
        "userAlreadyInTeam": "The user {email} is already in a team."
      }
    },
    "pt": {
      "errors": {
        "default": "Ocorreu um erro ao inscrever a equipa.",
        "tournamentNotFound": "O torneio não foi encontrado.",
        "invalidOrganicUnit": "A unidade orgânica selecionada não é válida.",
        "invalidTournamentStage": "O torneio não se encontra na fase de inscrições.",
        "signupNotAvailable": "As inscrições para o torneio não estão disponíveis.",
        "teamNameAlreadyExists": "Já existe uma equipa com esse nome.",
        "alreadyLeaderOfTeam": "Já é líder de uma equipa.",
        "noPermissionsToCreate": "Não tem permissão para criar equipas para esta unidade orgânica.",
        "minPlayers": "Uma equipa tem de ter pelo menos {min_number} jogadores. Apenas {total} de {min_number} adicionados",
        "invalidEmail": "E-mail inválido.",
        "onlyStudents": "Apenas podem ser convidados estudantes.",
        "userAlreadyInTeam": "O utilizador {email} já faz parte de outra equipa."
      }
    }
  }
</i18n>
