<template>
  <div v-if="tournament" class="relative">
    <fw-panel :title="$t('settings')" featured :loading="loading"> </fw-panel>

    <LoadingPlaceholder v-if="loading || loadingSports" />

    <div v-else>
      <fw-panel
        :title="$t('generalDetails')"
        boxed="lg"
        class="my-5"
        custom-class="bg-white flex flex-col gap-3"
        :loading="savingData"
        after-loading-checked
      >
        <template #toolbar>
          <div class="flex gap-1 items-center">
            <div
              v-if="editionActive && $v.$error"
              class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium"
            >
              <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
              <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
            </div>
            <div v-if="canEdit" class="flex gap-5">
              <fw-button
                :type="!editionActive ? 'link' : 'link-light'"
                :disabled="savingData"
                :loading="savingData"
                @click.native="toggleEditMode()"
              >
                {{ editionActive ? $t('close') : $t('edit') }}
              </fw-button>
              <fw-button
                v-if="editionActive"
                type="primary"
                :disabled="savingData"
                :loading="savingData"
                @click.native="saveTournament()"
                >{{ $t('save') }}</fw-button
              >
              <!-- <fw-button
                v-if="!tournament.is_visible"
                type="primary"
                :disabled="savingData"
                :loading="savingData"
                @click.native="publishTournament()"
                >Publicar torneio</fw-button
              > -->
            </div>
          </div>
        </template>
        <div>
          <fw-label>{{ $t('title.label') }}</fw-label>
          <TranslatedInput
            v-if="editable"
            v-model="tournamentTmp.title"
            :placeholder="{ pt: $t('title.pt'), en: $t('title.en') }"
            :disable-autowrite="true"
            :multiline="false"
            :class="{
              error: $v.tournamentTmp.title.$error
            }"
          />
          <fw-panel-info v-else clean>
            <div v-for="lang in langs" :key="lang" class="flex gap-3 mb-2">
              <fw-tag class="uppercase">{{ lang }}</fw-tag>
              <span>{{ tournamentTmp.title?.[lang] ?? $t('notDefined') }}</span>
            </div>
          </fw-panel-info>
          <fw-tip v-if="editable && $v.tournamentTmp.title.$error" error>
            {{ $t('title.required') }}
          </fw-tip>
        </div>

        <div>
          <fw-label>{{ $t('description.label') }}</fw-label>
          <TranslatedInput
            v-if="editable"
            v-model="tournamentTmp.description"
            :placeholder="{ pt: $t('description.pt'), en: $t('description.en') }"
            :disable-autowrite="true"
            :multiline="true"
          />
          <fw-panel-info v-else clean>
            <div v-for="lang in langs" :key="lang" class="flex gap-3 mb-2">
              <fw-tag class="uppercase">{{ lang }}</fw-tag>
              <span>{{ tournamentTmp.description?.[lang] ?? $t('notDefined') }}</span>
            </div>
          </fw-panel-info>
          <fw-tip v-if="editable && $v.tournamentTmp.description.$error" error>
            {{ $t('description.required') }}
          </fw-tip>
        </div>

        <div>
          <fw-label>Modalidade</fw-label>
          <fw-panel-info clean>
            {{ `${tournamentSport?.modality?.title?.[language]} ${tournamentSport?.category?.[language] ?? ''}` }}
          </fw-panel-info>
        </div>

        <div>
          <fw-label>Género</fw-label>
          <p>
            <fw-tag class="uppercase">{{ gendersList[tournamentTmp.gender]?.[language] }}</fw-tag>
          </p>
        </div>
      </fw-panel>
    </div>
  </div>
</template>

<script>
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import { required, minLength } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import { GENDERS, TOURNAMENT_TYPES } from '@/utils/index.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    TranslatedInput,
    LoadingPlaceholder
  },

  props: {
    tournament: {
      type: Object,
      default: () => {
        return {
          genders: [],
          title: {
            pt: '',
            en: ''
          },
          description: {
            pt: '',
            en: ''
          },
          sport_key: null
        }
      }
    },

    league: {
      type: Object,
      default: () => {}
    },

    canEdit: {
      type: Boolean,
      default: true
    },

    savingData: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      editionActive: false,
      loadingSports: false,
      gendersList: GENDERS,
      typesList: TOURNAMENT_TYPES,
      minSignUpDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
      minDate: Dates.now()
        .add(1, 'day')
        .toDate(),
      sports: {},
      tournamentTmp: {}
    }
  },

  computed: {
    editable() {
      return this.canEdit && this.editionActive
    },

    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language || 'pt'
    },

    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },

    langs() {
      return [this.language, this.secondaryLanguage]
    },

    tournamentSport() {
      return this.sports?.[this.tournamentTmp.sport_key]
    }
  },

  mounted() {
    this.getData()
    this.tournamentTmp = JSON.parse(JSON.stringify(this.tournament))

    console.log('this.tournamentTmp  :>> ', this.tournamentTmp)
  },

  validations() {
    return {
      tournamentTmp: {
        title: {
          pt: { required, min: minLength(1) },
          en: { required, min: minLength(1) }
        },
        description: {
          pt: { required, min: minLength(1) },
          en: { required, min: minLength(1) }
        }
      }
    }
  },

  methods: {
    saveTournament() {
      this.$v.$touch()
      if (this.$v.tournamentTmp.$invalid) return
      this.$emit('save-tournament', { ...this.tournamentTmp, stages: null })
    },

    // publishTournament() {
    //   this.$buefy.dialog.confirm({
    //     title: `Publicar torneio`,
    //     cancelText: 'Cancelar',
    //     confirmText: 'Publicar',
    //     message: `Tem a certeza que deseja publicar este torneio?`,
    //     onConfirm: () => {
    //       this.$emit('save-tournament', { is_visible: true })
    //     },
    //   })
    // },

    async getData() {
      this.loadingSports = true
      this.getSports()
      this.loadingSports = false
    },

    async getSports() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getSports()
        console.log('getSports :>> ', response)
        this.sports = {}

        for (let index = 0; index < response.sports.length; index++) {
          const sport = response.sports[index]
          this.sports[sport.key] = sport
        }
      })
    },

    toggleEditMode() {
      this.editionActive = !this.editionActive
    }
  }
}
</script>
<i18n>
{
  "pt": {
    "settings": "Configurações",
    "thereAreErrors": "Existem erros no formulário",
    "close": "Fechar",
    "edit": "Editar",
    "save": "Guardar",
    "delete": "Remover",
    "generalDetails": "Detalhes gerais",
    "tournamentTitle": "Título",
    "date": {
      "label": "Data",
      "placeholder": "Escolha uma data"
    },
    "startDate": {
      "label": "Data de início",
      "min": "Escolha uma data mais recente",
      "required": "Insira uma data de início"
    },
    "endDate": {
      "label": "Data de fim",
      "min": "Escolha uma data a seguir a data de início",
      "required": "Insira uma data de fim"
    },
    "title": {
      "label": "Título",
      "pt": "Título em Português",
      "en": "Título em Inglês",
      "required": "Insira um título em Portugês e Inglês."
    },
    "description": {
      "label": "Descrição",
      "pt": "Descrição em Português",
      "en": "Descrição em Inglês",
      "required": "Insira uma descrição em Portugês e Inglês."
    },
    "charsLimit": "Limite de {limit} caracteres.",
    "notDefined": "Não definido"
  },
  "en": {
    "settings": "Settings",
    "generalDetails": "General details",
    "tournamentTitle": "Title",
    "thereAreErrors": "There are errors in the form",
    "close": "Close",
    "edit": "Edit",
    "save": "Save",
    "delete": "Remove",
    "date": {
      "label": "Date",
      "placeholder": "Choose a date"
    },
    "startDate": {
      "label": "Start date",
      "min": "Choose a more recent date",
      "required": "Enter a start date"
    },
    "endDate": {
      "label": "End Date",
      "min": "Choose a date after the start date",
      "required": "Enter an end date"
    },
    "title": {
      "label": "Title",
      "pt": "Title in Portuguese",
      "en": "English title",
      "required": "Enter a title in English and Portuguese."
    },
    "description": {
      "label": "Description",
      "pt": "Description in Portuguese",
      "en": "Description in English",
      "required": "Enter a description in English and Portuguese."
    },
    "charsLimit": "Limit of {limit} characters.",
    "notDefined": "Not defined"
  }
}
</i18n>
